<template>
    <div>
      <select-shop
        :loadingButton="loadingButton"
        v-on:getData="getCashInOutReport"
      ></select-shop>
  
      <div v-if="isPermission">
        <CCard class="shadow-sm">
          <CCardBody>
            <div class="row">
          <h2 class="col-md-10 col-sm-9 col-8 font-weight-normal d-flex  align-items-center">
            <span id="cashInOutTitle" >{{ $t('report.txtCashInOut') }}</span>
          </h2>
              <div
                v-if="isExport"
                class="col-md-2 col-sm-3 col-4 text-right text-success"
              >
                <CButton id="cashinoutExportButton" v-on:click="exportCashInOutReport()" block color="info">
                  <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
                </CButton>
              </div>
            </div>
            
            <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              
              <hr />
              <DataTable
                id="cashinoutDatatable"
                style="white-space:nowrap"
                :items="items"
                :fields="fields"
                hover
                border
                :loading="loading"
              >
              </DataTable>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getCashInOutReport">
          </pagination>
          </CCardBody>
        </CCard>
      </div>
      <div v-else>
        <access-data></access-data>
      </div>
    </div>
  </template>
  

  <script>
  import { mapGetters } from 'vuex'
  import moment from 'moment'
  import axios from '@/services/service'
  import report from '@/services/report'
  import Pagination from '@/containers/Pagination'
  import Sortdata from '@/containers/SortData'
  import DataTable from '@/containers/DataTable'
  import permis from '@/util/permission'
  import util from '@/util/util'
  
  export default {
    components: {
      Pagination,
      Sortdata,
      DataTable,
    },
    data() {
      return {
        data: [],
        meta_data: {
          last_page: null,
          current_page: 1,
          prev_page_url: null,
          items: 0,
          limit: null,
          itemCount: null,
        },
        loadingButton: true,
        
      }
    },
    computed: {
      ...mapGetters(['shops', 'date', 'users']),
      isPermission() {
        return permis.findPermissionRead('report', this.$route.path)
      },
      isExport() {
        return permis.findPermissionExport('report', this.$route.path)
      },
      uid() {
        let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
        return shop.uid
      },
      shopObjectId: {
        get() {
          return this.$store.getters.shopObjectId
        },
        set(newValue) {
          return this.$store.dispatch('setShop', newValue)
        },
      },
      startDate() {
        return moment(String(this.date.start)).format('DD MMMM YYYY')
      },
      endDate() {
        return moment(String(this.date.end)).format('DD MMMM YYYY')
      },
      fields() {
        return [
          {
            key: 'date',
            label: this.$i18n.t('date'),
            _style: 'width:15%',
            _classes: 'text-left text-dark font-weight-normal'
          },
          { key: 'roundBill', 
            label: this.$i18n.t('billingCycle'), 
            _classes: 'text-center text-dark font-weight-normal',
            _style: 'width:8%',
          },
          {
            key: 'cashRoundCategoryType',
            label: this.$i18n.t('transactionType'),
            _classes: 'text-left text-dark font-weight-normal',
            _style: 'width:15%',
          },
          {
            key: 'drawerCate',
            label: this.$i18n.t('drawerCate'),
            _classes: 'text-left text-dark font-weight-normal',
            _style: 'width:15%',
          },
          {
            key: 'descri',
            label: this.$i18n.t('detail'),
            _classes: 'text-left text-dark font-weight-normal',
            _style: 'width:20%',
          },
          {
            key: 'totalAmount',
            label: this.$i18n.t('grandTotal'),
            _classes: 'text-right text-dark font-weight-normal',
            _style: 'width:15%',
          },
          {
            key: 'updatedBy',
            label: this.$i18n.t('saveBy'),
            _classes: 'text-left text-dark font-weight-normal',
            _style: 'width:10%',
          },
        ]
      },
      items() {
      let data = this.data
      let detail = []
      let roundBill = ''
      let drawerCate = ''
      let descri = ''
      let totalAmount = ''
      let updatedBy = ''
      for (let i = 0; i < data.length; i++) {
        if (data[i].cashRoundCategory !== undefined) {
          if (data[i].cashRoundCategory.name !== undefined) {
            drawerCate = data[i].cashRoundCategory.name
          }
        }

        if (!data[i].desc)  {
          descri = '-'
        } else {
          descri = data[i].desc
        }

        if (data[i].cashRound == undefined) {
          roundBill = '-'
        } else {
          if (data[i].cashRound.roundNo == undefined) {
            roundBill = '-'
          } else {
            roundBill = data[i].cashRound.roundNo
          }
        }
        if (data[i].type === undefined) {
          this.cashRoundCategoryType = ''
        } else if (data[i].type === 1) {
          this.cashRoundCategoryType = this.$i18n.t('cashOut')
        } else {
          this.cashRoundCategoryType = this.$i18n.t('cashIn')
        }

        if (data[i].updated_by == undefined) {
          updatedBy = '-'
        } else {
          if (data[i].updated_by.username == undefined) {
            updatedBy = '-'
          } else {
            updatedBy = data[i].updated_by.username
          }
        }
        if (data[i].amount === undefined) {
          totalAmount = '0.00'
        } else {
          totalAmount = util.convertCurrency(data[i].amount)
        }

        detail.push({
          date: moment(String(data[i].created_at)).format('DD/MM/YYYY HH:mm:ss'),
          roundBill : roundBill,
          cashRoundCategoryType : this.cashRoundCategoryType,
          drawerCate: drawerCate,
          descri: descri,
          totalAmount: totalAmount,
          updatedBy: updatedBy,
        })
      }
      return detail
      
    },
      
    },
    created() {
    this.getCashInOutReport()
  },
    methods: {
      ...util,
    setSort(value) {
      this.sortdata = value
      this.getCashInOutReport()
    },
    getCashInOutReport(page = 1) {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      this.loading = true
      axios({
        url: '/cash/v1.0/' + uid + '/inout',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
        
    },
    exportCashInOutReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }

      report({
        url: '/cash/v1.0/' + uid + '/inout/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานการนำเงินเข้า-นำเงินออก.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  <style src="../../../src/assets/styles/sortCss.css"></style>
